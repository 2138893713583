




































































































































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'v-input',
  props: {
    toggleable: {
      type: Boolean,
      default: true,
    },
    label: String,
    id: String,
    mask: String,
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: [String, Number, Boolean],
    name: String,
    placeholder: String,
    minlength: Number,
    maxlength: Number,
    pattern: String,
    disabled: Boolean,
    errors: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const input = ref<HTMLInputElement>()
    const isEditable = ref(!props.toggleable)
    const toggleEditable = () => {
      isEditable.value = !isEditable.value

      if (isEditable.value) {
        input.value?.focus()
      } else {
        input.value?.blur()
      }
    }

    return {
      input,
      toggleEditable,
      isEditable,
    }
  },
})
