var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hasUser && _vm.content)?_c('div',{staticClass:"home"},[_c('div',{staticClass:"wrapper--no-padding"},[_c('div',{staticClass:"row sections"},[_c('div',{staticClass:"intro p-xl col-m-8"},[_c('h1',{staticClass:"mb-xxs intro__title"},[_vm._v(_vm._s(_vm.$t('landing.title')))]),_c('h2',{staticClass:"intro__subtitle mb-xs"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.query.token ? 'landing.signature' : 'landing.space'))+" ")]),_c('img',{staticClass:"intro__picture mb-xs",attrs:{"src":require("@/assets/images/cloud-documents.svg"),"alt":"Documents"}}),(_vm.$route.query.token)?_c('p',{staticClass:"intro__excerpt"},[_vm._v(_vm._s(_vm.$t('landing.slides.cloud.title')))]):_vm._e()]),_c('div',{staticClass:"login p-xl col-m-8",class:{ 'is-loading': _vm.isLoading }},[(_vm.isExpired)?_c('div',{staticClass:"mb-m error h2 intro__error"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"icons-alert"}}),_vm._v(" "+_vm._s(_vm.$t('profile.expired'))+" ")]):_vm._e(),(_vm.isBroker && _vm.connectionData.connection_token)?_c('broker-login',{staticClass:"mb-l",on:{"on-change":_vm.updateConnectionState}}):_vm._e(),(_vm.isLoginVisible && !_vm.err.code)?_c('div',{staticClass:"h1 mb-s"},[_vm._v(_vm._s(_vm.$t('landing.login')))]):_vm._e(),(_vm.isLoginVisible || (_vm.err && _vm.err.code === 401))?[(_vm.err && _vm.err.code === 401)?_c('div',{staticClass:"mb-s"},[_c('h1',[_vm._v(_vm._s(_vm.$t('landing.errors.login.title')))]),_c('h2',{staticClass:"mb-xxs"},[_vm._v(_vm._s(_vm.$t('landing.errors.login.subtitle')))]),_c('div',{staticClass:"login__error__description",domProps:{"innerHTML":_vm._s(_vm.$t('landing.errors.login.description'))}})]):_vm._e(),_c('g-btn',{staticClass:"login__ied login__btn btn--big--outline--reverse",attrs:{"tag":"a","btn":{
              url: _vm.content.t1t.auth_url,
              icon: {
                symbol: 'icons-id-reader',
                size: '0 0 24 24',
              },
            }},nativeOn:{"click":function($event){return _vm.onAuthBtnClick($event)}}},[_vm._v(" "+_vm._s(_vm.$t('landing.eid'))+" ")]),_c('g-btn',{staticClass:"login__btn btn--big--outline--reverse",attrs:{"tag":"a","btn":{ url: _vm.content.itsme.auth_url + _vm.connectionToken('ITSME') }},nativeOn:{"click":function($event){return _vm.onAuthBtnClick($event)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/images/itsme-logo.svg")}})]},proxy:true}],null,false,214844566)},[_vm._v(" "+_vm._s(_vm.$t('landing.itsme'))+" ")]),_c('p',{staticClass:"login__eid-mobile mt-s p"},[_vm._v(_vm._s(_vm.$t('landing.eidMobile')))]),_c('g-link',{staticClass:"mt-s",attrs:{"label":_vm.$t('landing.cantConnect.label'),"href":_vm.$t('landing.cantConnect.url')}})]:_vm._e(),(_vm.err && _vm.err.code && _vm.err.code !== 401)?_c('feedback-message',{staticClass:"mt-xs",attrs:{"content":{
            type: 'error',
            htmltext: _vm.err.description || _vm.$t(("sign.errors." + (_vm.err.code))),
          }}}):_vm._e()],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }