












































import { defineComponent, ref } from '@vue/composition-api'

import VInput from '@/components/form/VInput.vue'

export default defineComponent({
  name: 'broker-login',
  components: { VInput },
  props: {},

  setup(props, ctx) {
    const root = ref({} as HTMLFormElement)
    const isLoading = ref(false)
    const brokerFormData = ref({
      firstname: '',
      lastname: '',
      email: '',
      birthday: '',
    })

    const onChange = () => {
      ctx.emit('on-change', {
        ...brokerFormData.value,
        birthday: brokerFormData.value.birthday.split('/').reverse().join('-'),
      })
    }

    return { root, onChange, brokerFormData, isLoading }
  },
})
